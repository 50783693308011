import  React, { useState } from "react"
import { Link } from "gatsby"

import SlideOut from "@ijsto/react-slideout";


import LocationIcon from "../images/svg/location-icon.svg";
import EmailIcon from "../images/svg/mail-icon.svg";
import PhoneIcon from "../images/svg/phone-icon.svg";




const Nav =()=>{

    const [slideOutIsOpen, setSlideOutIsOpen] = useState(false);

    const openSlideOut = () => {
      setSlideOutIsOpen(true);
    };
  
    const closeSlideOut = () => {
      setSlideOutIsOpen(false);
    };




    return(
        
        <nav>
        <div className="nav-contact">
        <span><PhoneIcon/>+27 (0)28 384 0343</span>
        {/* <span><EmailIcon/>info@marine5hotel.com</span> */}
        <span><LocationIcon/>Haarlem Street, De Kelders</span>
        </div>

        <div className="brand container-fluid">

        <div className="mobile-menu">
            <div onClick={openSlideOut}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
            </div>

            <SlideOut isOpen={slideOutIsOpen} onClose={closeSlideOut}>
            <div className="mobile-nav-items">
                <Link to="/" >
                    Home
                </Link>

                <Link to="/rooms/" >
                    Rooms
                </Link>

                <Link to="/gallery/" >
                    Gallery
                </Link>

                <Link to="/contact/" >
                    Contact
                </Link>

                    
                    <a href="https://book.nightsbridge.com/21145" className="book-mobile">Book now</a>
            </div>
                
            </SlideOut>
        </div>
        <div className="text-logo">
            <h2 className="logo-main-text">Marine 5</h2>
            <p className="logo-sub-text">Boutique Hotel</p>
        </div>

        </div>

        <ul className="nav-items">
        
            <li><Link to="/" >Home</Link></li>
        

        
            <li><Link to="/rooms/" >Rooms</Link></li>
        

        
            <li><Link to="/gallery/" >Gallery</Link></li>
        
            
            <li><Link to="/contact/" >Contact</Link></li>
        

            
            <li className="book-now"><a href="https://book.nightsbridge.com/21145" target="_blank" rel="noreferrer">Book now</a></li>
            

        </ul>




    </nav>
    )
    

}

export default Nav
import * as React from "react"
import { Link } from "gatsby"

import FacebookIcon from "../images/svg/facebook-icon.svg";
import TripadvisorIcon from "../images/svg/tripadvisor-icon.svg";

import LocationIcon from "../images/svg/location-icon.svg";
import EmailIcon from "../images/svg/mail-icon.svg";
import PhoneIcon from "../images/svg/phone-icon.svg";


const Footer =()=>{

    return(
        <div className="footer">
            <div className="container-2 ">
                <div className="row footer-row">
                    <div className="col-md-4 col-xs-12 col-sm-6 footer-item footer-info-con">
                        <div className="footer-info">
                            <h4>Marine 5</h4>
                            <p>
                                The Marine 5 Guesthouse has upgraded to The Marine 5 Boutique Hotel.
                                Come see the beautiful surroundings and with accommodation that is directly on the
                          beach, it's easy to see why we are one of the best Hermanus whale watching spots.
                                {/* whale watching hermanus */}
                            </p>
                        </div>
                        <div className="social-icons">
                        <a href="https://www.facebook.com/Marine-5-Guesthouse-276999413123992/">
                        <FacebookIcon/>
                        </a>
                        </div>

                    </div>

                    <div className="col-md-2 col-xs-4 col-sm-6  links footer-item">
                        <h5>Links</h5>
                        <Link to="/" >Home</Link>

                        <Link to="/rooms/" >Rooms </Link>

                        <Link to="/gallery/" > Gallery </Link>

                        <Link to="/contact/" >Contact</Link>
                    </div>

                    <div className="col-md-3 col-xs-8 footer-contact footer-item">
                        <h5>Contact info</h5>
                        <span><PhoneIcon/>+27 (0)28 384 0343</span>
                        {/* <span><EmailIcon/>info@marine5hotel.com</span> */}
                        <span><LocationIcon/>Haarlem Street, De Kelders</span>
                    </div>

                    <div className="col-md-3 footer-item footer-find-con">
                        <div className="footer-find">
                        <h5>Find us on</h5>
                        <div className="booking-icons">
                        
                        <a 
                            href="https://www.tripadvisor.co.za/Hotel_Review-g4420543-d12223701-Reviews-Marine5_Guesthouse-De_Kelders_Overstrand_Overberg_District_Western_Cape.html#REVIEWS"
                            target="_blank"
                            rel="noreferrer"
                        >
                        <TripadvisorIcon/>
                        </a>

                        </div>
                        </div>
                    </div>


                </div>
 
            </div>
            <div className="copyright">
                <div className="container-2">
                    <p>all rights reserved 2022</p>
                </div>
            
            </div> 
        </div>

    )
    

}

export default Footer